import { useContext, useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthenticationContext } from '@contexts/Authentication';
import { Loading } from '@components';

function ProtectedRoute({ component: Component, ...restOfProps }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const {agentId, loadUserData} = useContext(AuthenticationContext)

  useEffect(() => {
    if (!loadUserData) return;

    // Check if the user data has already been loaded
    if (agentId) {
      setIsAuthenticated(true)
      setIsLoading(false)
      return
    }

    // load it otherwise
    loadUserData()
      .then(isUserLoaded => { setIsAuthenticated(isUserLoaded) })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))

  }, [agentId, loadUserData, isAuthenticated]);

  if (isLoading) return <Loading bigLoading />

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/sign-in" />
      }
    />
  );
}

export default ProtectedRoute;
