import { useCallback, useEffect, useState, useContext, useRef } from 'react';
import * as cevents from 'up.lib.js.cevents';
import { EVENTS_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS, OnboardDeviceIdentificationStatus, OnboardDeviceType, TripInfo } from '@type';
import { deepRemovePII, trackingTripInfo } from '@helpers/Utils';
import { AGENT_VERSION } from '@helpers/Constants';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@contexts/Global';

// Gets client ID from cookies for cevents
export const getCid = () => {
    const cookies = document.cookie;

    if (!cookies.length) {
      return;
    }

    const cookiesArray = cookies.split('; ');

    for (const cookie of cookiesArray) {
      const [name, value] = cookie.split('=');

      // e.g. "1.2.1887949290.1646940325" => "1887949290.1646940325"
      if (name === '_up') {
        return value.split('.')?.slice(2)?.join('.') || null;
      }
    }

    return null;
};

export function useTracking() {
    const { i18n } = useTranslation(['']);
    const { globalData } = useContext(GlobalContext);
    const [tracker, setTracker] = useState<any>({ trackingReady: false });
    const generalData = useRef({
        localization: {
            country: 'US',
            currency: 'USD',
            language: i18n.language
        }
    });

    useEffect(() => {
        if (!globalData?.tripInfo?.localization) return;

        generalData.current = {
            localization: {
                ...globalData?.tripInfo?.localization,
                language: i18n.language
            }
        }
    }, [globalData?.tripInfo?.localization, i18n.language])

    const cevent = useCallback((eventCategory : EVENTS_CATEGORIES, eventAction: EVENT_ACTIONS | string,
                    eventLabel: EVENT_LABELS | string, event: any = {}) => {
        try {
          (window as any).up('send', 'event', { ec: eventCategory, ea: eventAction, el: eventLabel, ev: {
              ...deepRemovePII(event),
              agent: globalData?.tripInfo.agent.agent_id || null,
              version: AGENT_VERSION,
              'pre-offer-id': event['pre-offer-id'] || globalData?.preOfferId,
              localization: {
                country: generalData.current.localization.country || 'US',
                language:  generalData.current.localization.language,
                region: "",
                currency: generalData.current.localization?.currency || 'USD'
            },
          } })
        } catch (e) {
            console.error('up.js.error:', e);
        }

    }, [globalData?.preOfferId, globalData?.tripInfo.agent.agent_id]);

    useEffect(() => {
        if (!cevent) return;

        setTracker({
            trackingReady: true,
            trackModal(modalName: string, data = {}) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionModalView,
                              modalName,
                              data);
            },
            trackAuth(type: string, verificationCode: string) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionAuth,
                              type,
                              { 'verification-code': verificationCode })
            },
            trackAuthResult(type: string, result: string, verificationCode = '') {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionAuth,
                              `${type}-result-${result}`,
                              { verification_code: verificationCode })
            },
            trackMfa(type: string) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionAuthMfa,
                              type, {})
            },
            trackSignInError(errorType: string, verificationCode: string) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionAuth,
                              'sign-in-error',
                              { 'verification-code': verificationCode, err_type: errorType })
            },
            trackPageMount(page: string) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionView,
                              EVENT_LABELS.eventLabelDidMount,
                              { page });
            },
            trackLangChange(langPrefOrder: Array<string>) {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionMouseup,
                              'lang-change',
                              { lang_pref_order: langPrefOrder });
            },
            trackBeforeUnload() {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionBeforeUnload,
                              '',
                              {});
            },
            trackCardMount(card: string) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionCardView,
                              `${card}-${EVENT_LABELS.eventLabelDidMount}`,
                              {});
            },
            trackFormBlur(elementId: string) {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionBlur,
                              elementId,
                              {});
            },
            trackModalClose(name = '') {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionMouseup,
                              EVENT_LABELS.eventLabelModalClose + '-' + name,
                              {});
            },
            trackClick(label: string, preOfferId?: string) {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionMouseup,
                              label,
                              { 'pre-offer-id': preOfferId });
            },
            trackCheck(label: string, preOfferId: string, checked: boolean) {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionMouseup,
                              label,
                              { 'pre-offer-id': preOfferId, checked });
            },
            trackTripSelect(selected: string, trips: Array<string>) {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionMouseup,
                              selected,
                              {'trip-sel': trips});
            },
            trackStepFinished(currentStep: string, orderAmount: number, tripInfo: TripInfo, order_id?: string) {
                return cevent(EVENTS_CATEGORIES.eventCategoryUser,
                              EVENT_ACTIONS.eventActionMouseup,
                              EVENT_LABELS.eventLabelStepFinished,
                              {
                                  step: currentStep,
                                  'order-amount': orderAmount,
                                  'trip-data': trackingTripInfo(tripInfo),
                                  'order-id': order_id || tripInfo.order_id
                              });
            },
            trackDash(eventLabel: string, event: any, eventAction = 'um-action') {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              eventAction,
                              eventLabel,
                              event);
            },
            trackUserManagment(eventLabel: string, event: any) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              'um-action',
                              eventLabel,
                              event);
            },
            trackTenantManagment(eventLabel: string, eventAction: string, event: any) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              eventAction,
                              eventLabel,
                              event);
            },
            trackAxios(handler: string, method: string, event = {}) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              method.toLowerCase(),
                              handler,
                              event);
            },
            trackAxiosResult(handler: string, method: string, result: any) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              method.toLowerCase(),
                              handler,
                              result ? { result } : {});
            },
            trackSubmit(orderId: string, virtualCard: string, tripInfo: TripInfo) {
                return cevents.sendSubmit({
                    'card-bin': virtualCard.slice(0, 6),
                    'card-last-4': virtualCard.slice(-4),
                    agent: globalData?.tripInfo.agent.agent_id || null,
                    'trip-data': trackingTripInfo(tripInfo),
                    'order-id': orderId,
                    v: AGENT_VERSION
                });
            },
            trackResponse(confirmationId: string, orderId: string) {
                return cevents.sendResponse({
                    'malu-order-id': orderId,
                    agent: globalData?.tripInfo.agent.agent_id || null,
                    'order-id': confirmationId,
                    v: AGENT_VERSION
                });

            },
            trackOnboardSpendFlowType(applicationOnSameDevice: boolean, application_status: OnboardDeviceIdentificationStatus) {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                    EVENT_ACTIONS.eventActionIdentifyDevice,
                    application_status === "started" ?
                        EVENT_LABELS.eventLabelDeviceIdentificationStart :
                        EVENT_LABELS.eventLabelDeviceIdentificationEnd,
                    {
                        device_type: applicationOnSameDevice ?
                            OnboardDeviceType.salesAgentDevice : OnboardDeviceType.personalDevice,
                        application_status
                    });
            },
            trackVOIPError() {
                return cevent(EVENTS_CATEGORIES.eventCategorySys,
                              EVENT_ACTIONS.eventActionView,
                              EVENT_LABELS.eventLabelAlert,
                              { alert: "voip_true"});
            },
        });
    }, [cevent, globalData?.tripInfo.agent.agent_id]);

    return tracker;
}
