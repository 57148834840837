import version from '../version';

export const CruiseLines: any = {
    carnival: "Carnival Cruise Line",
    celebrity: "Celebrity Cruises",
    costa: "Costa Cruises",
    disney: "Disney Cruise Line",
    holland: "Holland America Line",
    bpcl: "Margaritaville at Sea",
    msc: "MSC Cruises",
    ncl: "Norwegian Cruise Line",
    princess: "Princess Cruises",
    quarkexpeditions: "Quark Expeditions",
    royal: "Royal Caribbean International",
    seabourn: "Seabourn",
    virgin: "Virgin Voyages",
    hurtigruten: "Hurtigruten",
    uniworld: "Uniworld River Cruises",
    viking: "Viking",
    cunard: "Cunard",
    other: "Other",
};

// These are also considered "Cruise Lines" insofar as onboard spend is concerned
// the keys of this object should not conflict with the CruiseLines constant
export const InPortLocations: any = {
  effy: [
    "Amber Cove - 38",
    "Antigua - 47",
    "Aruba - 13",
    "Bahamas - 09",
    "Bahamas - 19",
    "Bahamas - 45",
    "Cayman - 11",
    "Cayman Island - 48",
    "Cozumel - 35",
    "Cozumel - 40",
    "Cozumel - 41",
    "Curacao - 43",
    "Grand Turk - 20",
    "Juneau - 21",
    "Ketchikan - 3",
    "Skagway - 17",
    "St Kitts - 34",
    "St Kitts - 46",
    "St Lucia - 24",
    "St Maarten - 31 Harbor",
    "St Maarten - 36 Front St",
    "St Maarten - 42 Harbor",
    "St Thomas - 28 Downtown",
    "St Thomas - 30 Crown Bay",
    "St Thomas - 44 Havensight",
    "Tortola - 37",
  ],
  "effy-land": [
    "5th Ave Store"
  ]
}

export const ShipCodeOptions: any = {
  bpcl: [
    "Margaritaville Paradise",
    "Margaritaville at Sea Islander"
  ],
  carnival: [
    "Carnival Breeze",
    "Carnival Celebration",
    "Carnival Conquest",
    "Carnival Dream",
    "Carnival Elation",
    "Carnival Firenze",
    "Carnival Freedom",
    "Carnival Glory",
    "Carnival Horizon",
    "Carnival Jubilee",
    "Carnival Legend",
    "Carnival Liberty",
    "Carnival Luminosa",
    "Carnival Magic",
    "Mardi Gras",
    "Carnival Miracle",
    "Carnival Panorama",
    "Carnival Paradise",
    "Carnival Pride",
    "Carnival Radiance",
    "Carnival Spirit",
    "Carnival Splendor",
    "Carnival Sunrise",
    "Carnival Sunshine",
    "Carnival Valor",
    "Carnival Venezia",
    "Carnival Vista"
  ],
  celebrity: [
    "Celebrity Apex",
    "Celebrity Ascent",
    "Celebrity Beyond",
    "Celebrity Constellation",
    "Celebrity Eclipse",
    "Celebrity Edge",
    "Celebrity Equinox",
    "Celebrity Flora",
    "Celebrity Infinity",
    "Celebrity Millennium",
    "Celebrity Reflection",
    "Celebrity Silhouette",
    "Celebrity Solstice",
    "Celebrity Summit",
    "Celebrity Xcel",
    "Celebrity Xpedition",
    "Celebrity Xploration"
  ],
  costa: [
    "Costa Deliziosa",
    "Costa Diadema",
    "Costa Fascinosa",
    "Costa Favolosa",
    "Costa Fortuna",
    "Costa Pacifica",
    "Costa Serena",
    "Costa Smeralda",
    "Costa Toscana",
  ],
  disney: [
    "Disney Adventure",
    "Disney Destiny",
    "Disney Dream",
    "Disney Fantasy",
    "Disney Magic",
    "Disney Treasure",
    "Disney Wish",
    "Disney Wonder"
  ],
  holland: [
    "MS Eurodam",
    "MS Koningsdam",
    "MS Nieuw Amsterdam",
    "MS Nieuw Statendam",
    "MS Noordam",
    "MS Oosterdam",
    "MS Rotterdam",
    "MS Volendam",
    "MS Westerdam",
    "MS Zaandam",
    "MS Zuiderdam"
  ],
  msc: [
    "MSC Armonia",
    "MSC Bellissima",
    "MSC Divina",
    "MSC Euribia",
    "MSC Fantasia",
    "MSC Grandiosa",
    "MSC Lirica",
    "MSC Magnifica",
    "MSC Meraviglia",
    "MSC Musica",
    "MSC Opera",
    "MSC Orchestra",
    "MSC Poesia",
    "MSC Preziosa",
    "MSC Seascape",
    "MSC Seashore",
    "MSC Seaside",
    "MSC Seaview",
    "MSC Sinfonia",
    "MSC Splendida",
    "MSC Virtuosa",
    "MSC World America",
    "MSC World Asia",
    "MSC World Europa",
  ],
  ncl: [
    "Norwegian Aqua",
    "Norwegian Bliss",
    "Norwegian Breakaway",
    "Norwegian Dawn",
    "Norwegian Encore",
    "Norwegian Epic",
    "Norwegian Escape",
    "Norwegian Gem",
    "Norwegian Getaway",
    "Norwegian Jade",
    "Norwegian Jewel",
    "Norwegian Joy",
    "Norwegian Luna",
    "Norwegian Pearl",
    "Pride of America",
    "Norwegian Prima",
    "Norwegian Sky",
    "Norwegian Spirit",
    "Norwegian Star",
    "Norwegian Sun",
    "Norwegian Viva",
  ],
  princess: [
    "Caribbean Princess",
    "Coral Princess",
    "Crown Princess",
    "Diamond Princess",
    "Discovery Princess",
    "Emerald Princess",
    "Enchanted Princess",
    "Grand Princess",
    "Island Princess",
    "Majestic Princess",
    "Regal Princess",
    "Royal Princess",
    "Ruby Princess",
    "Sapphire Princess",
    "Sky Princess",
    "Star Princess",
    "Sun Princess"
  ],
  quarkexpeditions: [
    "Ocean Explorer",
    "Ultramarine",
    "World Explorer"
  ],
  royal: [
    "Adventure of the Seas",
    "Allure of the Seas",
    "Anthem of the Seas",
    "Brilliance of the Seas",
    "Enchantment of the Seas",
    "Explorer of the Seas",
    "Freedom of the Seas",
    "Grandeur of the Seas",
    "Harmony of the Seas",
    "Icon of the Seas",
    "Independence of the Seas",
    "Jewel of the Seas",
    "Liberty of the Seas",
    "Mariner of the Seas",
    "Navigator of the Seas",
    "Oasis of the Seas",
    "Odyssey of the Seas",
    "Ovation of the Seas",
    "Quantum of the Seas",
    "Radiance of the Seas",
    "Rhapsody of the Seas",
    "Serenade of the Seas",
    "Spectrum of the Seas",
    "Star of the Seas",
    "Symphony of the Seas",
    "Utopia of the Seas",
    "Vision of the Seas",
    "Voyager of the Seas",
    "Wonder of the Seas"
  ],
  seabourn: [
    "Seabourn Pursuit",
    "Seabourn Venture",
    "Seabourn Ovation",
    "Seabourn Encore",
    "Seabourn Quest",
    "Seabourn Sojourn"
  ],
  virgin: [
    "Brilliant Lady",
    "Resilient Lady",
    "Scarlet Lady",
    "Valiant Lady"
  ],
  hurtigruten: ["Hurtigruten"],
  uniworld: ["Uniworld River Cruises"],
  viking: ["Cruise", "Expedition", "River"],
  cunard: [
    "Queen Mary 2",
    "Queen Victoria",
    "Queen Elizabeth",
    "Queen Anne"
  ]
}

export enum PartnerTypes {
  NonTravel = 'non-travel',
  Travel = 'travel',
  Membership = 'membership'
};

export const AGENT_VERSION = version;

export enum APPLICATION_STATUSES {
  none = '',
  send_application = 'send_application',
  receive_payment = 'receive_payment',
  trip_booked = 'trip_booked',
  agent_ended = 'agent_ended',
  wait_for_client = 'wait_for_client',
  offer_loaded = 'offer_loaded',
  loan_approved = 'loan_approved',
  loan_review = 'loan_review',
  loan_reconfirm = 'loan_reconfirm',
  session_ended = 'session_ended'
}

export const DEMO_CARD = "4111111111111111";
export const DEMO_CARD_OVERRIDE = {
  "card_ccv": "987",
  "expiration_month": 4,
  "name_on_card": "Arthur Davis",
  "expiration_year": 2027,
  "card_number": "4999999999999999",
  "contact": {
      "postal_code": "12345",
      "email": "arthur@uplift.com",
      "first_name": "Arthur",
      "phone": "5555551648",
      "city": "Mayfield",
      "region": "KY",
      "last_name": "Davis",
      "street_address": "1013 Weda Cir",
      "country": "US"
  },
  "card_type": "VISA"
}

export const DEMO_CARD_OVERRIDE_CA = {
  "card_ccv": "987",
  "expiration_month": 4,
  "name_on_card": "Arthur Davis",
  "expiration_year": 2027,
  "card_number": "4999999999999999",
  "contact": {
      "postal_code": "N0M 1G0",
      "email": "arthur@uplift.com",
      "first_name": "Arthur",
      "phone": "5555551648",
      "city": "Bayfield",
      "region": "ON",
      "last_name": "Davis",
      "street_address": "59 Victoria St",
      "country": "CA"
  },
  "card_type": "VISA"
}
