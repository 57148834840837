import enUSTranslations from './enUS.json';
import frCATranslations from './frCA.json';
import i18next from 'i18next';

export default function initI8nExt() {

    i18next.init({
        interpolation: { escapeValue: false },
        resources: {
            'en': {
                ...enUSTranslations
            },
            'fr': {
                ...frCATranslations
            }
        },
        lng: "en",
        fallbackLng: "en",
        react: {
            transKeepBasicHtmlNodesFor: [
                'span',
                'div',
                'section',
                'br',
                'strong',
                'i',
                'p',
                'li',
                'ul',
                'table',
                'tbody',
                'tr',
                'th',
                'td',
                'h1',
                'h2',
                'h3',
                'h4',
                'h5',
                'h6',
                'b',
                'a',
                'style',
                'em'
            ]
        }
    })
}