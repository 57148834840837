import { fetchAuthSession } from "aws-amplify/auth"
import { AuthenticationContext } from '@contexts/Authentication';
import { GlobalContext } from '@contexts/Global';
import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios';
import { useCallback, useContext } from 'react';
import { useTracking } from './useTracking';

declare type useSafeAxiosProps = {
    useIdToken?: boolean;
    removeJWTHeader?: boolean;
}

export function useSafeAxios({ useIdToken, removeJWTHeader }: useSafeAxiosProps = { useIdToken: false, removeJWTHeader: false }) {
    const { trackAxios, trackAxiosResult, trackingReady } = useTracking();
    const { setGlobalShutdown } = useContext(GlobalContext);
    const { refreshAccessToken } = useContext(AuthenticationContext);

    const fetchSettings = useCallback(async () => {
        if (!trackAxiosResult) return;

        return new Promise((resolve, reject) => {
            // Just for the login, because it does not have global context
            // Global context is for logged users
            if (!setGlobalShutdown)
                return resolve({});

            axios({
                url: `${process.env.REACT_APP_TABI_API_BASE}/tabi/sa/v5/settings`,
                //url: 'http://localhost:8000/tabi/sa/v3o/settings',
                method: 'GET'
            })
                .then(({ data }: any) => {
                    setGlobalShutdown({
                        settingsFetched: true,
                        universalAlert: data['universal-alert'],
                        universalShutdown: data['universal-shutdown'],
                        dictionary: {
                            en: {
                                universalShutdownMsg: data['dictionary']['en']['universal-shutdown-msg'],
                                universalAlertMsg: data['dictionary']['en']['universal-alert-msg']
                            },
                            fr: {
                                universalShutdownMsg: data['dictionary']['fr']['universal-shutdown-msg'],
                                universalAlertMsg: data['dictionary']['fr']['universal-alert-msg']
                            }
                        }
                    });
                    resolve(data);
                })
                .catch((error: any) => {
                    // The status code and text is returned inside the request
                    const { request: { status, statusText } } = error;
                    trackAxiosResult('get-settings-fail', "GET", {
                        error: error.message,
                        status,
                        statusText
                    });

                    reject(error);
                });
        });
    }, [setGlobalShutdown, trackAxiosResult]);

    const makeAxiosCall = useCallback((request: AxiosRequestConfig, api: AxiosInstance, handler: string, track = true,
        trackResponse = false, trackingData: any | null = null, trackingResponseFunc?: Function, errCount = 0) => {
        if (!trackAxios) return;
        if (!trackAxiosResult) return;
        if (!fetchSettings) return;
        if (errCount > 1) return;

        if (track) trackAxios(handler, request.method, trackingData ? trackingData : request.data);

        return new Promise(async (resolve, reject) => {
            fetchAuthSession().then(authSession => {
                let secureHeaders: any = {};

                secureHeaders.authorization = useIdToken ? authSession?.tokens?.idToken : authSession.tokens?.accessToken

                if (!removeJWTHeader) {
                    secureHeaders.jwt = authSession.tokens?.idToken
                }


                request.headers = {
                    ...request.headers,
                    ...secureHeaders
                };

                const trackResult = (err: boolean, response: any) => {
                    const trackResponseData = trackResponse && trackingResponseFunc ? trackingResponseFunc(err, response) : {}
                    trackAxiosResult(handler + (err ? '-fail' : '-success'), request.method, trackResponse ? trackResponseData : {});
                }

                fetchSettings()
                    .then(() => {
                        api(request)
                            .then((response: AxiosResponse) => {
                                if (track) trackResult(false, response);
                                resolve(response);
                            })
                            .catch(async (error: any) => {
                                if (track) trackResult(true, error);

                                if (error?.isAxiosError
                                    && error?.response?.status === 403) {
                                    await refreshAccessToken();
                                    return makeAxiosCall(request, api, handler, track, trackResponse, trackingData, trackingResponseFunc, errCount + 1)
                                }
                                reject(error);
                            });
                    })
                    .catch(reject)

            });
        })
    }, [trackAxiosResult, trackAxios, fetchSettings, useIdToken, refreshAccessToken, removeJWTHeader]);

    if (!trackingReady) return {};
    if (!fetchSettings) return {};

    return { makeAxiosCall, fetchSettings }
}