import { ResourcesConfig } from "aws-amplify"

const config: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL || "",
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || "",
    //   identityPoolId: "<your-cognito-identity-pool-id>",
    //   loginWith: {
    //     email: true,
    //   },
    //   signUpVerificationMethod: "code",
    //   userAttributes: {
    //     email: {
    //       required: true,
    //     },
    //   },
    //   allowGuestAccess: true,
    //   passwordFormat: {
    //     minLength: 8,
    //     requireLowercase: true,
    //     requireUppercase: true,
    //     requireNumbers: true,
    //     requireSpecialCharacters: true,
    //   },
    },
  },
}

export default config