import { AirReservations, HotelReservations, TripTypes, TripInfo, CruiseReservations,
         AdminTypes, PassesSubscription, Recipient, InsuranceType, Reservations, BRAND } from "@type";
import { ShipCodeOptions, CruiseLines, PartnerTypes } from "@helpers/Constants";
import { AgentFilterGroup, AgentFilterOption, Role, Agent as NewAgent } from "@contexts/Agents";
import { GlobalData } from "@contexts/Global";

declare type BuilderParams = {
    startDate: string | Date | number,
    endDate: string,
    oneWay: boolean,
    travelType: PartnerTypes,
    name: string,
    tripInfo: TripInfo,
    tripsSelected: Array<TripTypes>,
}

const DEFAULT_BUILDER_PARAMS = {
    travelType: PartnerTypes.NonTravel,
    name: 'other'
}

const TRIP_BUILDERS: any = {
    hotel: ({startDate, endDate, oneWay}: BuilderParams): HotelReservations => {
        return {
            hotel_name: 'standalone agent hotel',
            check_in: formatDate(new Date(startDate)),
            check_out: formatDate(new Date(oneWay ? startDate : endDate))
        };
    },
    flight: ({startDate, endDate, oneWay}: BuilderParams): AirReservations => {

        const itinerary = [{
            departure_time: formatDate(new Date(startDate)),
            arrival_time: formatDate(new Date(startDate))
        }];

        if (!oneWay) {
            itinerary.push({
                departure_time: formatDate(new Date(endDate)),
                arrival_time: formatDate(new Date(endDate))
            });
        }

        return {
            trip_type: oneWay ? 'oneway' : 'roundtrip',
            itinerary
        };
    },
    cruise: ({startDate, endDate, oneWay}: BuilderParams): CruiseReservations => {
        const itinerary = [{
            departure_date: formatDate(new Date(startDate)),
            arrival_date: formatDate(new Date(startDate))
        }];

        if (!oneWay) {
            itinerary.push({
                departure_date: formatDate(new Date(endDate)),
                arrival_date: formatDate(new Date(endDate))
            });
        }

        return {
            embark_date: formatDate(new Date(startDate)),
            disembark_date: formatDate(new Date(oneWay ? startDate : endDate)),
            itinerary
        }
    },
    car: ({startDate, endDate, oneWay}: BuilderParams) => {
        return {
            quantity: 1,
            start_date: formatDate(new Date(startDate)),
            end_date: formatDate(new Date(oneWay ? startDate : endDate)),
            type: "car",
            rental_items: [
                {
                  id: "car rental agent standalone",
                  name: "car rental agent standalone",
                }
            ]
        };
    },
    insurance: ({startDate, endDate, oneWay, tripsSelected, travelType}: BuilderParams) => {
        if (travelType !== PartnerTypes.NonTravel && hasMainProduct(tripsSelected, travelType)) {
            return [{
                types: [InsuranceType.travel],
            }]
        }

        return {
            hotel_name: 'insurance',
            check_in: formatDate(new Date(startDate)),
            check_out: formatDate(new Date(oneWay ? startDate : endDate))
        };
    },
    other: ({startDate, endDate, oneWay, travelType, name, tripsSelected}: BuilderParams) => {
        if (travelType === PartnerTypes.NonTravel) {
            return {
                quantity_unit: "unit",
                name: typeof name === "string" ? name : 'other',
                quantity: 1,
            }
        }

        if (hasMainProduct(tripsSelected, travelType)) {
            return {
                id: "add on",
                name: "add on standalone"
            }
        }

        if (travelType === PartnerTypes.Membership) {
            return {
                name: 'add on',
                type: 'membership',
                quantity: 1,
            }
        }

        return {
            hotel_name: 'add on',
            check_in: formatDate(new Date(startDate)),
            check_out: formatDate(new Date(oneWay ? startDate : endDate))
        };
    },
    membership: ({ travelType }: BuilderParams) => {
        if (travelType === PartnerTypes.Membership)
            return {
                name: 'standalone membership',
                type: 'membership',
                quantity: 1,
            }

        return {
            quantity_unit: "unit",
            name: "membership",
            quantity: 1,
        }
    },
    fractional: ({ travelType }: BuilderParams) => {
        if (travelType === PartnerTypes.Membership)
            return {
                name: 'standalone fractional',
                type: 'membership',
                quantity: 1,
            }

        return {
            quantity_unit: "unit",
            name: "fractional",
            quantity: 1,
        }
    },
    package: ({startDate, endDate, oneWay, travelType, tripsSelected}: BuilderParams) => {
        if (hasMainProduct(tripsSelected, travelType) && travelType !== PartnerTypes.NonTravel) {
            return {
                id: "package",
                name: "package standalone"
            }
        }

        if (travelType === PartnerTypes.Travel) {
            return {
                hotel_name: 'package',
                check_in: formatDate(new Date(startDate)),
                check_out: formatDate(new Date(oneWay ? startDate : endDate))
            };
        }

        if (travelType === PartnerTypes.Membership) {
            return {
                name: 'package',
                type: 'membership',
                quantity: 1,
            }
        }

        return {
            quantity_unit: "unit",
            name: "package",
            quantity: 1,
        }
    },
};

const TRACKING_TRIP_BUILDERS: any = {
    hotel: (tripInfo: TripInfo) => {
        if (!tripInfo.hotel_reservations) return {};
        if (tripInfo.hotel_reservations.length === 0) return {};

        return tripInfo.hotel_reservations.find((h: HotelReservations) => h.hotel_name === 'standalone agent hotel');
    },
    flight: (tripInfo: TripInfo) => {
        if (!tripInfo.air_reservations) return {};
        if (!tripInfo.air_reservations[0]) return {};

        return tripInfo.air_reservations[0].itinerary;
    },
    cruise: (tripInfo: TripInfo) => {
        if (!tripInfo.cruise_reservations) return {};
        if (!tripInfo.cruise_reservations[0]) return {};

        const isCustomCruise = isCustomCruiseLine(tripInfo.cruise_reservations[0].cruise_line)

        return {
            cruise_line: isCustomCruise ? 'other' : tripInfo.cruise_reservations[0].cruise_line,
            other_cruise_line: isCustomCruise ? tripInfo.cruise_reservations[0].cruise_line : '',
            ship_code: tripInfo.cruise_reservations[0].ship_code,
            embark_date: tripInfo.cruise_reservations[0].embark_date,
            disembark_date: tripInfo.cruise_reservations[0].disembark_date,
            itinerary: tripInfo.cruise_reservations[0].itinerary
        }
    },
    car: (tripInfo: TripInfo) => {
        if (!tripInfo.rentals) return;
        if (!tripInfo.rentals[0]) return;

        return tripInfo.rentals[0]
    }
};

const AGENT_STATUS_MAP : any = {
   FORCE_CHANGE_PASSWORD: 'pending',
   CONFIRMED: 'active',
   RESET_PASSWORD: 'active'
}
const TRAVEL_MAIN_TRIP_TYPES: any = {
    [TripTypes.hotel]: true,
    [TripTypes.flight]: true,
    [TripTypes.cruise]: true,
    [TripTypes.car]: true,
    [TripTypes.membership]: true
}

const MEMBERSHIP_MAIN_TRIP_TYPES: any = {
    [TripTypes.membership]: true,
    [TripTypes.fractional]: true,
}

const TRIP_TYPES_NOT_ALLOWED_BE_SINGLE = [TripTypes.insurance];

export function getMainTrips(partnerType: PartnerTypes) {
    switch(partnerType) {
        case PartnerTypes.Membership:
            return MEMBERSHIP_MAIN_TRIP_TYPES;
        default:
            return TRAVEL_MAIN_TRIP_TYPES;
    }
}

export function hasMainProduct (products: Array<TripTypes>, partnerType: PartnerTypes = PartnerTypes.Travel) {
    const mainTrips = getMainTrips(partnerType);

    return products?.some((product) => (mainTrips[product]))
}

export function parseToken(token: string){
    if (!token || token === '{}') return '';

    return decodeURIComponent(
        window.atob(
            token.split('.')[1]
                 .replace('-', '+')
                 .replace('_', '/')
            )
            .split('')
            .map((letter) => {
                const code = '00' + letter.charCodeAt(0).toString(16);
                return '%' + code.slice(-2);
            })
            .join('')
        );
}

export function tokenFromStorage() {
    const jwt = parseToken(sessionStorage.getItem('jwt') || '')
    if (!jwt) return;
    return JSON.parse(jwt);
}

export const formatDate = (date: any, separator = '') => {
    if (!date) return '';
    if (date?.toString() === 'Invalid Date') return '';

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = (new Date(date - tzoffset)).toISOString();

    return localISOTime.split('T')[0]
                       .replaceAll('-', separator);
}

export const getBuilder = (tripType: TripTypes): Function => {
    const isRetailItem = tripType as string === 'retail item';

    if (isRetailItem) {
        tripType = TripTypes.other;
    }

    const builder = TRIP_BUILDERS[tripType];

    return (builderParams: BuilderParams) => {
        let params = {
            ...DEFAULT_BUILDER_PARAMS,
            ...builderParams,
            ...(isRetailItem ? { name: 'retail item' } : {})
        };

        return builder(params);
    }
}

export const deformatCurrency = (formatedAmount: string): number => {
    return parseInt(formatedAmount.replaceAll(/[$-.\s,]/gmi, ''));
}

export const defaultTripDetails = (amount: number, prevTrip: TripInfo, industry = PartnerTypes.Travel) : TripInfo => {
    if (industry !== PartnerTypes.Travel) {
        const builder = getBuilder(TripTypes.other);
        const nonTravelTripInfo = {
            // ...prevTrip,
            localization: {
                ...prevTrip.localization
            },
            agent: {
                email: prevTrip.agent.email,
                agent_id: prevTrip.agent.agent_id
            },
            order_amount: amount,
            order_lines: [builder({oneWay: false, travelType: industry, name: 'non travel item'})],
            merchant_data: { path: '/' },
            brand: prevTrip.brand,
            up_code: prevTrip.up_code,
        }

        return nonTravelTripInfo;
    }

    return {
        // ...prevTrip,
        localization: {
            ...prevTrip.localization
        },
        agent: {
            email: prevTrip.agent.email,
            agent_id: prevTrip.agent.agent_id
        },
        order_amount: amount,
        hotel_reservations: [],
        air_reservations: [],
        cruise_reservations: [],
        travelers: [{id: 0}],
        merchant_data: { path: '/' },
        brand: prevTrip.brand,
        up_code: prevTrip.up_code,
    };
};

export const dummyTripDetails = (amount: number, prevTrip: TripInfo, industry = PartnerTypes.Travel) : TripInfo => {
    if (industry === PartnerTypes.NonTravel) {
        const builder = getBuilder(TripTypes.other);
        return {
            ...prevTrip,
            agent: {
                email: prevTrip.agent.email,
                agent_id: prevTrip.agent.agent_id
            },
            order_amount: amount,
            order_lines: [builder({oneWay: false, travelType: industry, name: 'non travel item'})],
            merchant_data: { path: '/' },
            brand: prevTrip.brand,
        }
    }

    if (industry === PartnerTypes.Membership) {
        const builder = getBuilder(TripTypes.membership);

        return {
            ...prevTrip,
            agent: {
                email: prevTrip.agent.email,
                agent_id: prevTrip.agent.agent_id
            },
            order_amount: amount,
            passes_subscription: [builder({travelType: industry})],
            merchant_data: { path: '/' },
            brand: prevTrip.brand,
        }
    }

    const hotelBuilder = getBuilder(TripTypes.hotel);
    const cruiseBuilder = getBuilder(TripTypes.cruise);
    const flightBuilder = getBuilder(TripTypes.flight);

    const defaultBuilderParams = {
        // Today plus 11 days (thats usually the max booking window)
        startDate: Date.now() + 960000000,
        // Today plus 12 days (one day more that the startDate)
        endDate: Date.now() + 1060000000,
        oneWay: true
    }

    return {
        ...prevTrip,
        agent: {
            email: prevTrip.agent.email,
            agent_id: prevTrip.agent.agent_id
        },
        order_amount: amount,
        hotel_reservations: [hotelBuilder(defaultBuilderParams)],
        cruise_reservations: [cruiseBuilder(defaultBuilderParams)],
        air_reservations: [flightBuilder(defaultBuilderParams)],
        travelers: [{id: 0}],
        merchant_data: { path: '/' },
        brand: prevTrip.brand,
    };
};

export const isValidTripInfo = (tripInfo: TripInfo, industry = PartnerTypes.Travel) => {
    if (industry === PartnerTypes.NonTravel) {
        return tripInfo.order_lines && tripInfo.order_lines.length > 0;
    }

    if (tripInfo.travelers && tripInfo.travelers.length === 0) return false;

    const reservations = [
        ...(tripInfo.hotel_reservations || []),
        ...(tripInfo.air_reservations || []) ,
        ...(tripInfo.cruise_reservations || []),
        ...(tripInfo.rentals || []),
        ...(tripInfo.add_ons || []),
        ...(tripInfo.passes_subscription || []),
        ...(tripInfo.order_lines || [])]

    if (reservations.length === 0) return false;

    return true;
}

export const formatCurrency = (cents: number, language = 'en', currency = 'USD', decimals = 2, addCurreny = false) => {
    const locale = language === 'en' ? 'en-US' : 'fr-CA';
    // const noDecimals = (cents/100)  === Math.ceil(cents/100);

    const formatedPrice = (cents / 100).toLocaleString(locale, {
      style: 'currency', currency, minimumFractionDigits: /*noDecimals ? 0 :*/ decimals, maximumFractionDigits: /*noDecimals ? 0 : */decimals
    });

    const finalFormatted = language === 'fr' ? formatedPrice.split('$')[0] + '$' : (language === 'en' && currency === 'CAD' ? '$' + formatedPrice.split('$')[1] : formatedPrice);
    return addCurreny ? finalFormatted + ' ' + currency: finalFormatted;
};

export const formatPhone = (phone?: string, mask = '(999) 999-9999') => {
    if (!phone) return '';

    let index = 0;
    let cleanPhone = phone;

    if (phone.length > 10) {
        cleanPhone = phone.slice(-10);
    }

    return mask.split('').reduce((acc, current) => {
        if (current === '9') return acc + (cleanPhone[index++] || '');

        return acc + current;
    }, '');
}

export const unformatPhone = (phone: string) => {
    return phone.replaceAll(/[()\s-_]/g, '');
}

export const isEmptyObj = (obj: any): boolean => {
    if (obj === null) return true;

    return Object.keys(obj).length === 0;
};

export const getShipCodeOptios = (cruiseLine: string): Array<string> => {
    return ShipCodeOptions[cruiseLine] || [];
}

export const tripInfoToTripsSelected = (tripInfo: TripInfo): Array<string> => {
    const trips : Array<string> = [];

    if (tripInfo.hotel_reservations?.length) {
        if (tripInfo.hotel_reservations.some(t => t.hotel_name === 'insurance')) trips.push(TripTypes.insurance);
        if (tripInfo.hotel_reservations.some(t => t.hotel_name === 'add on')) trips.push(TripTypes.other);
        if (tripInfo.hotel_reservations.some(t => t.hotel_name === 'standalone agent hotel')) trips.push(TripTypes.hotel);
        if (tripInfo.hotel_reservations.some(t => t.hotel_name === 'package')) trips.push(TripTypes.package);
    }

    if (tripInfo.air_reservations && tripInfo.air_reservations?.length !== 0) trips.push(TripTypes.flight);
    if (tripInfo.cruise_reservations && tripInfo.cruise_reservations?.length !== 0) trips.push(TripTypes.cruise);
    if (tripInfo.rentals && tripInfo.rentals?.length !== 0) trips.push(TripTypes.car);
    if (tripInfo.add_ons && tripInfo.add_ons?.length !== 0) trips.push(TripTypes.other);

    if (!tripInfo.order_lines) return trips;

    for (let i = 0; i < (tripInfo.order_lines.length || 0); i++) {
        const orderLine = tripInfo.order_lines[i];

        trips.push(orderLine.name);
    }

    return trips;
}

export const isCustomCruiseLine = (cruiseLine: string | undefined) => cruiseLine ? !CruiseLines[cruiseLine]: false;

export const mergeObjects = (objectA: any, objectB: any) => {
    return {
        ...objectA,
        ...objectB
    }
}

export const partnerTypeByTripInfo = (tripInfo: TripInfo) => {
    if (tripInfo.hotel_reservations?.length
        || tripInfo.air_reservations?.length
        || tripInfo.cruise_reservations?.length
        || tripInfo.rentals?.length
        || tripInfo.passes_subscription?.length) return PartnerTypes.Travel;

    return PartnerTypes.NonTravel;
};

export const travelDatesByTripInfo = (tripInfo: TripInfo) => {
    let departureString = '', arrivalString = '', oneWay = true;

    if (tripInfo.air_reservations && tripInfo.air_reservations.length !== 0) {
        oneWay = tripInfo.air_reservations[0].trip_type === 'oneway';
        departureString = tripInfo.air_reservations[0].itinerary[0].departure_time;
        arrivalString = tripInfo.air_reservations[0].itinerary[0].arrival_time;

        if (!oneWay) {
            arrivalString = tripInfo.air_reservations[0].itinerary[1].departure_time;
        }

        return [datesFromNonSeparatorString(departureString), datesFromNonSeparatorString(arrivalString), oneWay];
    }

    if (tripInfo.cruise_reservations && tripInfo.cruise_reservations?.length !== 0) {
        departureString = tripInfo.cruise_reservations[0].itinerary[0].departure_date;
        arrivalString = tripInfo.cruise_reservations[0].itinerary[0].arrival_date;

        oneWay = tripInfo.cruise_reservations[0].itinerary.length === 1;

        if (!oneWay) {
            arrivalString = tripInfo.cruise_reservations[0].itinerary[1].departure_date;
        }

        return [datesFromNonSeparatorString(departureString), datesFromNonSeparatorString(arrivalString), oneWay];
    }

    if (tripInfo.hotel_reservations && tripInfo.hotel_reservations.length !== 0) {
        departureString = tripInfo.hotel_reservations[0].check_in;
        arrivalString = tripInfo.hotel_reservations[0].check_out;

        return [datesFromNonSeparatorString(departureString), datesFromNonSeparatorString(arrivalString), departureString === arrivalString];
    }

    if (tripInfo.rentals && tripInfo.rentals.length !== 0) {
        departureString = tripInfo.rentals[0].start_date;
        arrivalString = tripInfo.rentals[0].end_date;
        return [datesFromNonSeparatorString(departureString), datesFromNonSeparatorString(arrivalString), departureString === arrivalString];
    }

    return [departureString, arrivalString, oneWay];
}

export const datesFromNonSeparatorString = (dateString: string) => {
    const digits = dateString.split('');

    if (digits.length !== 8) return new Date('Invalid date');

    const year = digits.slice(0, 4);
    const month = digits.slice(4, 6);
    const day = digits.slice(6);

    return [year.join(''), month.join(''), day.join('')].join('/');
}

export const isThridPartyLoanByTripInfo = (tripInfo: TripInfo) => {
    if (!tripInfo.travelers) return false;
    if (tripInfo.travelers && tripInfo.travelers.length === 0) return false;
    if (!tripInfo.billing_contact) return false;

    const firstTraveler = tripInfo.travelers[0];
    const billingContact = tripInfo.billing_contact;

    return firstTraveler.first_name !== billingContact.first_name
          || firstTraveler.last_name !== billingContact.last_name;
}

export const agentStatusByCognitoStatus = (cognitoStatus: string) => {
    return AGENT_STATUS_MAP[cognitoStatus];
}

export const agentMultiRolesDisplay = (roles: Array<Role>, groupString: string, t: any) => {
    if (!roles || roles.length === 0) return '';
    if (roles.length < 4) return roles.map((role: Role) => role.name).join(',');

    return t(groupString,{ count: roles.length });
}

export const superPowers = (roles: Array<Role>) => {
    if (!roles || roles.length === 0) return false;

    const upliftRoles = roles.filter(role => role.tenant_id.toString() === process.env.REACT_APP_UPLIFT_TENANT_ID);

    if (upliftRoles.length === 0) return false;

    const containSuperAdmin = upliftRoles.some(role => role.name === process.env.REACT_APP_UPLIFT_SUPER_ADMIN_ROLE);
    const containUpliftAdmin = upliftRoles.some(role => role.name === process.env.REACT_APP_UPLIFT_ADMIN_ROLE);

    if (containUpliftAdmin && containSuperAdmin) return true;

    return false;
}

const AGENT_FILTER_CONVERTOR_MAP: any = {
    status: (options: Array<AgentFilterOption>) => {
        // Options[2] is when filtering by disabled status
        if (options[2].checked) return '&enabled=false';

        // Any other option should get only enabled users
        let parsed = "&enabled=true";

        parsed += `&statuses=${options.filter(o => o.checked).map(o => o.value).join(',')}`;

        return parsed;
    },
    search_query: (options: any, filter: AgentFilterGroup) => {
        if (filter.value) return `&${filter.id}=${encodeURIComponent(filter.value)}`;

        return '';
    }
};

export const agentFiltersToQueryString = (filters: any) => {
    return filters.reduce((prev: string, filter: AgentFilterGroup) => {
        const activeFilters = filter.options.filter(o => o.checked);

        if (activeFilters.length === 0 && !filter.value) return prev;

        const convertor = AGENT_FILTER_CONVERTOR_MAP[filter.id];

        if (convertor) return prev + convertor(filter.options, filter);

        return prev + `&${filter.id}=${activeFilters.map(o => o.value).join(',')}`;
    }, '');
}

export const generateNewAgent = (): NewAgent => ({
    name: '',
    'custom:version': '',
    'custom:certified': false,
    'custom:mfa_disabled': false,
    email: '',
    enabled: false,
    family_name: '',
    phone_number: '',
    product: 'standalone',
    tenant: [],
    app_role: [],
    gums_role: [],
    validated: false,
    valid: false,
    status: ""
});

export const trackingTripInfo  = (tripInfo: any) => {
    if (!tripInfo) return {};

    const trips = tripInfoToTripsSelected(tripInfo);

    return trips.reduce((prev: any, current: string) => {
        const transformer = TRACKING_TRIP_BUILDERS[current];
        if (typeof transformer === 'function'){
            prev[current === TripTypes.flight ? 'air': current] = transformer(tripInfo);
        } else {
            if (!prev.order_lines) prev.order_lines = [];
            prev.order_lines.push(current);
        }

        return prev;
    }, {});
}

export const todayPlusDays = (days: number) => {
 const millisecondsInADay = 86400000;
 const today = new Date();
 return new Date(today.getTime() + (days * millisecondsInADay) + (today.getTimezoneOffset() * 60000))
}

export const scrollToTargetAdjusted = (element: any, headerOffset: number) => {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
         top: offsetPosition,
         behavior: "smooth"
    });
}

export const cleanNonTravelInfo = (tripInfo: TripInfo) => {
    const { travelers, hotel_reservations, air_reservations, cruise_reservations, order_id, ...rest} = tripInfo;

    return rest;
}

export const cleanPhoneNumber = (phone: string) => {
    return unformatPhone(phone).slice(-10);
}

export const selfAdminType = () => {
    const token = tokenFromStorage();

    if (!token || !token['custom:acl']) return AdminTypes.noAdmin;

    const userACL = JSON.parse((token['custom:acl']))

    if (userACL.gums.length === 0) return AdminTypes.noAdmin;

    const upliftGumRoles = userACL.gums
                                  .filter((role: any) => role.resource.tenant_id.toString() === process.env.REACT_APP_UPLIFT_TENANT_ID)

    const somePartners = userACL.gums.length > 1;
    const superAdmin = containsAction(upliftGumRoles, "**");
    const csAdmin = containsAction(userACL.gums, "power");
    const upliftAdmin = upliftGumRoles.some((role: any) => {
        return ['write', 'read'].every(x => role.actions.includes(x));
    });

    if (superAdmin && upliftAdmin) return AdminTypes.omniAdmin;
    if (superAdmin) return AdminTypes.superAdmin;
    if (csAdmin) return AdminTypes.csAdmin;
    if (somePartners) return AdminTypes.multiAdmin;

    return AdminTypes.singleAdmin;
}

export const containsAction = (roles: Array<any>, action: string) => {
    return roles
            .some((role) => role.actions.includes(action));
}

export const cruiseNameToCruiseLineKey = (name: string) => {
    const cruiseLine = Object.keys(CruiseLines)
                             .find(key => CruiseLines[key] === name)

    return cruiseLine;
}

export const fetchSessionTrips = () => {
    const sessionTripsRaw = sessionStorage.getItem('sessionTrips');

    if (!sessionTripsRaw) return {};

    return JSON.parse(sessionTripsRaw);
}

export const addSessionTripInfo = (sessionTripInfo: any) => {
    const sessionTrips = fetchSessionTrips();

    if (!sessionTripInfo.orderId) return;

    const orderId = sessionTripInfo.orderId

    delete sessionTripInfo.orderId;
    delete sessionTripInfo.tripInfo.order_id;
    sessionTrips[orderId] = sessionTripInfo;

    sessionStorage.setItem('sessionTrips', JSON.stringify(sessionTrips));
}

export const fetchSessionTrip = (orderId: string) => {
    const sessionTrips = fetchSessionTrips();

    return sessionTrips[orderId];
}

export const interpolateString = (str: string, data: { [key: string]: string; } = {}) => {
    return Object.keys(data).reduce((curr, key) => {
        return curr.replaceAll(`{{${key}}}`, data[key]);
    }, str);
}

export const isMembershipPartner = (partnerType: PartnerTypes, components: string[] = []): boolean => {
    return (partnerType === PartnerTypes.Travel &&
             (components.includes(TripTypes.membership) || components.includes(TripTypes.fractional)))
}

export const fillPassAndSubscription = (tripInfo: TripInfo, recipient: Recipient, partnerType: PartnerTypes = PartnerTypes.Travel) => {
    if (!tripInfo.passes_subscription) return tripInfo;

    tripInfo.passes_subscription = tripInfo.passes_subscription.map((pass: PassesSubscription) => {

        return {
            ...pass,
            recipients: [recipient],
        }
    })

    return {
        ...tripInfo,
        ...(partnerType === PartnerTypes.Membership ? { travelers: [recipient] } : {})
    };
}
export const sortReservationsByTravelType = (resevations : Array<[TripTypes, any]>, partnerType: PartnerTypes = PartnerTypes.Travel): Array<[TripTypes, any]> => {
    const mainReservations = getMainTrips(partnerType);

    return resevations.sort(([ typeA ], [ typeB ]) => {
        if (mainReservations[typeA] && mainReservations[typeB]) return 0;
        if (mainReservations[typeA]) return -1;
        if (mainReservations[typeB]) return 1;

        return 0;
    })
}

export const getFirstMainReservation = (tripInfo : TripInfo, industry: PartnerTypes = PartnerTypes.Travel) : Reservations | null | PassesSubscription => {
    const reservations = [
        ...(tripInfo.hotel_reservations || []).filter((h: HotelReservations)  => h.hotel_name === 'standalone agent hotel'),
        ...(tripInfo.air_reservations || []) ,
        ...(tripInfo.cruise_reservations || []),
        ...(tripInfo.rentals || []),
        ...(tripInfo.passes_subscription || [])
    ];


    return reservations[0] || null;
}

export const additionalProductsRequired = (tripsSelected : Array<TripTypes>) : boolean => {
    const tripsSelectedCount = tripsSelected.length;
    if (!tripsSelectedCount || tripsSelectedCount > 1) return false;

    return TRIP_TYPES_NOT_ALLOWED_BE_SINGLE.includes(tripsSelected[0]);
}

// This will take any string and return the last two digits
// In case its a string less than 2 characters, it will add a zero at the beginning
// Examples:
// 2023 -> 23
// 12 -> 12
// 6 -> 06
export const formatTwoDigitNumber = (rawNumber: number) => {
    const stringNumber = rawNumber.toString();
    if (stringNumber.length === 2) return stringNumber;
    if (stringNumber.length > 2) return stringNumber.substring(stringNumber.length - 2)

    return "0" + stringNumber;
}

// This will return whater the agent has the test user role assigned to him
export const hasTesterUserRole = (acls: any) => {
    // Get only Uplift app roles
    let upliftRoles = acls.app.filter(
        (role: any) => role.resource.tenant_id === +process.env.REACT_APP_UPLIFT_TENANT_ID!
        )

    // If there is any role that has any "test" action, then it has the tester role assigned
    return upliftRoles.some((role: any) => role.actions.some((action: string) => action === "test"))
}
const KEY_TO_REMOVE = ["name", "family_name", "email", "phone_number"]

// Remove any PII data from an object
export const deepRemovePII = (data: any, res: any = {}):any => {

    if (!(data instanceof Object)) return data
    if (data instanceof Array) return data.map((item) => deepRemovePII(item))

    for (let key in data) {
        // If its on the blacklist we just ignore it
        if (KEY_TO_REMOVE.includes(key)) {
            deepRemovePII(data[key], res)
        } else {
            if (data[key] instanceof Array) res[key] = data[key].map((item: any) => deepRemovePII(item))
            else res[key] = deepRemovePII(data[key])
        }
    }

    return res
}

// Get all the custom configs from a tenant, that will include multiple upcs.
const localConfigsCache: any = {};

export const customConfigTransform = (data: any) => {
    const dataFlows = data['button-flows'] || {};

    let flows: any = {
        // We check previous flag to ensure retrocompatibility
        showQrCode: dataFlows['show-qrcode'],
        showSendApplication: dataFlows['show-send-application'],
        showStartApplication: dataFlows['show-start-application'],
    }

    let result: any =  {
        components: data.components,
        'trip-options': data['trip-options'],
        'booking-window': data['booking-window'],
        'dictionary': data.dictionary,
        'filter': data.filter,
        'feature': data.feature,
        'subvention-default': data['subvention-default'],
        subventionCampaigns: data['subvention-campaigns'],
        isTokenizedPayment: data['is-tokenized-payment'],
        showSailAndSign: data['show-sail-and-sign'],
        hidePaymentConfirmation: data['hide-payment-confirmation'],
        application: {
            showAprToggle: data.application?.['show-apr-toggle'],
        },
        payment: {
            includeBarcodeExpiration: data.payment?.['include-barcode-expiration'],
        },
        overrideTitle: data['override-title'],
        onboardSpend: data['onboard-spend'],
        onboardSpendCruiseLines: data['onboard-spend-cruise-lines'],
        onboardSpendInPortLocations: data['onboard-spend-in-port-locations'],
        storeNames: data['store-names'],
        buttonFlows: flows,
        isWhiteLabel: data['is-white-label'],
        brand: BRAND.FLEXPAY
    }

    if (data['subvention-promo']) {
        result.subventionPromo = { requireCampaign: data['subvention-promo']['require-campaign'] };
    }

    return result;
}

export const getCustomConfigsPerTenant = (tabiApi: any, upcs: Array<string>) => {
    return new Promise((resolve, reject) => {
        const promises = [];
        for (let i = 0; i < upcs.length; i++) {
            const upc = upcs[i];

            var promise = tabiApi.customConfig(upc)
                .then(({ data }: any) => {
                    localConfigsCache[upc] = customConfigTransform(data)
                })
                .catch((error: any) => {
                    reject(error);
                })

            promises.push(promise);
        }

        return Promise.all(promises).then(() => resolve(localConfigsCache));
    });
}

export const fetchCustomConfig = (tabiApi: any, upc: string) => {
    return new Promise((resolve, reject) => {
        if (localConfigsCache[upc]) {
            resolve(localConfigsCache[upc]);
        } else {
            tabiApi.customConfig(upc)
                .then(({ data }: any) => {
                        const newConfig = customConfigTransform(data)
                        resolve(newConfig);
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
        }
    });
}

export const getCustomConfig = (tabiApi: any, upc: string, setGlobalData: Function) => {
    fetchCustomConfig(tabiApi, upc)
    .then((newConfig: any) => {
        setGlobalData((gd: GlobalData) => {
            if (!isMembershipPartner(gd.currentPartner!.industry, newConfig.components)) return {
                ...gd,
                currentPartnerConfig: newConfig,
                tripInfo: {
                    ...gd.tripInfo,
                    brand: newConfig.brand || BRAND.FLEXPAY,
                }
            };

            return {
                ...gd,
                currentPartner: {
                    ...gd.currentPartner,
                    industry: PartnerTypes.Membership
                },
                currentPartnerConfig: newConfig,
                tripInfo: {
                    ...gd.tripInfo,
                    brand: newConfig.brand || BRAND.FLEXPAY,
                }
            }
        });
    })
    .catch((error: any) => {
        setGlobalData((gd: GlobalData) => {
            return {
                ...gd,
                currentPartnerConfig: {},
                tripInfo: {
                    ...gd.tripInfo,
                    brand: BRAND.FLEXPAY,
                }
            }
        });
        })
    .finally(() => {});
}

export const showPromoLabel = (currentPartnerConfig: any, requireSelectPromo: boolean = false) => {
    if (!currentPartnerConfig || !Object.hasOwn(currentPartnerConfig, 'subventionPromo')) return false;
    return (!requireSelectPromo && !currentPartnerConfig.subventionPromo.requireCampaign) || (requireSelectPromo && currentPartnerConfig.subventionPromo.requireCampaign);
}